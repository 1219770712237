import React from 'react';

interface Feature {
  title: string;
  description: string;
  img: {
    src: string;
    alt: string;
  };
}

interface Props {
  feature: Feature;
}

export const FeatureItem: React.FunctionComponent<Props> = ({
  feature: {
    title,
    description,
    img: { src, alt },
  },
}) => {
  return (
    <div className="row py-3">
      <div className="col-md-6 feature-asset">
        <div className="feature-asset-image-container">
          <img className="feature-asset-image" src={src} alt={alt} />
        </div>
      </div>
      <div className="col-md-6 feature-description p-5">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};
