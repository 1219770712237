import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

import './footer.scss';

export const Footer: React.FC = () => {
  return (
    <footer className="footer bg-dark py-4">
      <div className="container">
        <div className="small text-center footer-text">
          Made with <FontAwesomeIcon icon={faHeart} color="#f47c3c" /> from
          Brighton
        </div>
      </div>
    </footer>
  );
};
