import React from 'react';

import { Home } from './home/home';
import { Benefits } from './benefits/benefits';
import { Features } from './features/features';

import './main.scss';

export const Main = () => {
  return (
    <>
      <Home />
      <main role="main">
        <Benefits />
        <Features />
        {/* <Portfolio /> */}
        {/* <Join /> */}
      </main>
    </>
  );
};
