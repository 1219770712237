/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-scroll';

import './navbar.scss';

interface State {
  show: boolean;
}

export class Navbar extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      show: false,
    };
  }

  toggleNavbar = () => {
    this.setState((prevState) => ({
      show: !prevState.show,
    }));
  };

  render() {
    const { show } = this.state;

    return (
      <div className="navbar navbar-expand-lg fixed-top navbar-dark navbar-primary">
        <div className="container">
          <Link
            className="navbar-brand"
            to="home"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            onClick={this.toggleNavbar}
          >
            Ongoney
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            aria-label="Toggle navigation"
            onClick={this.toggleNavbar}
          >
            <span className="navbar-toggler-icon" />
          </button>

          <div
            className={classNames('collapse navbar-collapse', {
              show,
            })}
            id="navbarColor01"
          >
            <ul className="navbar-nav ml-auto">
              <li className="nav-item" onClick={this.toggleNavbar}>
                <Link
                  className="nav-link"
                  to="benefits"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={this.toggleNavbar}
                >
                  Benefits
                </Link>
              </li>
              <li className="nav-item" onClick={this.toggleNavbar}>
                <Link
                  className="nav-link"
                  to="features"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={this.toggleNavbar}
                >
                  Features
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
