import React from 'react';

import { FeatureItem } from './feature-item';

import './features.scss';

const FEATURES = [
  {
    title: 'Customer Relationship Management',
    description:
      'Keep track of your deals. Get insights on what are the areas you could improve to have a robust selling process.',
    img: {
      src:
        'https://res.cloudinary.com/dcw7kvha3/image/upload/v1588507881/ong/crm.png',
      alt: 'crm',
    },
  },
  {
    title: 'Online Collaboration Tools',
    description:
      'Leverage your productivity with online collaboration tools. The members of your team will be able to work together and organize easily.',
    img: {
      src:
        'https://res.cloudinary.com/dcw7kvha3/image/upload/v1588507881/ong/collaboration-tools.png',
      alt: 'online collaboration tools',
    },
  },
  {
    title: 'Newsletters',
    description:
      'Get in touch with your community easily and programatically. Engage with your audience thorugh education and updates.',
    img: {
      src:
        'https://res.cloudinary.com/dcw7kvha3/image/upload/v1588507881/ong/newsletter.png',
      alt: 'newsletters',
    },
  },

  {
    title: 'Analytics',
    description:
      'Insights about how people interact with your site will help you understand better what they are looking for and improve the way you engage with them.',
    img: {
      src:
        'https://res.cloudinary.com/dcw7kvha3/image/upload/v1588507881/ong/analytics.jpg',
      alt: 'analytics',
    },
  },
];

export const Features = () => {
  return (
    <section id="features" className="features bg-light">
      <div className="container">
        <div className="d-flex justify-content-center p-5">
          <h2>Technology working for you</h2>
        </div>
        {FEATURES.map((feature) => (
          <FeatureItem key={feature.title} feature={feature} />
        ))}
      </div>
    </section>
  );
};
