import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCheck,
  faRocket,
  faMoneyCheckAlt,
} from '@fortawesome/free-solid-svg-icons';

import { colours } from '../../css/colours';
import { BenefitItem } from './benefit-item';

import './benefits.scss';

export const Benefits = () => {
  const BENEFITS = [
    {
      icon: (
        <FontAwesomeIcon
          size="4x"
          icon={faUserCheck}
          color={colours.$primary}
        />
      ),
      title: 'Satisfied Clients',
      description:
        'Better management will help you have better quality relationships with your clients.',
    },
    {
      icon: (
        <FontAwesomeIcon size="4x" icon={faRocket} color={colours.$primary} />
      ),
      title: 'Business Growth',
      description:
        'Improvement in your processes will provide a solid ground to be able to grow.',
    },
    {
      icon: (
        <FontAwesomeIcon
          size="4x"
          icon={faMoneyCheckAlt}
          color={colours.$primary}
        />
      ),
      title: 'Reduce Costs',
      description:
        'Benefit from the automation and flexibility that technology provides.',
    },
  ];

  return (
    <section id="benefits">
      <div className="benefits">
        <div className="container benefits-container">
          <div className="row">
            {BENEFITS.map((benefit) => (
              <BenefitItem
                key={benefit.title}
                icon={benefit.icon}
                title={benefit.title}
                description={benefit.description}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
