import React from 'react';
import { Link } from 'react-scroll';

import './home.scss';

export const Home = () => {
  return (
    <section className="home" id="home">
      <div className="background">
        <div className="jumbotron text-center">
          <div className="container">
            <h1 className="jumbotron-heading">Technology at your service</h1>
            <p className="lead text-description py-3">
              We leverage the efforts of your small company so you can have a
              bigger impact in the world.
            </p>
            <p>
              <Link
                className="btn btn-primary btn-lg m-2 join-button"
                to="features"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                More
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
